<template>
    <chat-ppt-scroll-wrapper></chat-ppt-scroll-wrapper>
</template>
<script>
    import ChatPptScrollWrapper from '@/components/scrollWrapper/ChatPPT.vue';
    export default {
        name: 'ChatPPT',
        components: {
            ChatPptScrollWrapper
        }
    }
</script>